.home {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .linkButton {
    background-color: #eaeaea;
    width: 300px;
    height: 60px;
    font-size: 20px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 5px 1px #7e7e7e;
  }

  .linkButton:active {
    transform: translate(0, 3px);
    box-shadow: 0px 3px 1px #7e7e7e;
  }
}
