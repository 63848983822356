.nameForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: bold;
  }

  input {
    width: 300px;
    height: 50px;
    font-size: 22px;
    padding-left: 10px;
    border: 0;
    border-bottom: solid 3px gray;
    border-radius: 10px;
  }

  input:focus {
    outline: none;
    box-shadow: 0 0 0 2px gray;
  }
}

.gameOver {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    text-align: center;
  }

  .linkButton {
    background-color: #eaeaea;
    width: 150px;
    height: 40px;
    font-size: 20px;
    margin-left: 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 1px #7e7e7e;
  }

  #playAgain {
    width: 305px;
    height: 40px;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .linkButton:active {
    transform: translate(0, 2px);
    box-shadow: 0px 3px 1px #7e7e7e;
  }
}

.questionCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  .playerInfo {
    p {
      width: 100%;
      display: flex;
      justify-content: center;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 22px;
      font-weight: bold;
      margin: 10px 0px;
    }

    .lives {
      display: flex;
      justify-content: center;

      .heart {
        --c: #f32732;
        width: 50px;
        aspect-ratio: 1;
        background:
         /* 1 */ radial-gradient(
              circle at 60% 65%,
              var(--c) 64%,
              #0000 65%
            )
            top left/50% 50%,
          /* 2 */ radial-gradient(circle at 40% 65%, var(--c) 64%, #0000 65%)
            top right/50% 50%,
          /* 3 */
            conic-gradient(from -45deg at 50% 85.5%, var(--c) 90deg, #0000 0)
            bottom/100% 50%;
        background-repeat: no-repeat;
      }
    }
  }

  h2 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

  .answerRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .answerButton {
    }

    .answerButton:active {
      transform: translate(0, 3px);
      box-shadow: 0px 2px 1px #7e7e7e;
    }
  }

  .score {
    margin-top: 10px;
    width: 300px;
    height: 60px;
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    border-radius: 10px;

    p {
      font-size: 20px;
      font-weight: bold;
      font-family: Arial, Helvetica, sans-serif;
      margin: 16px 0 0 0;
    }
  }
}
