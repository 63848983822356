.app {
  position: absolute;
  transform: translate(-8px, -8px);
  background-color: #83cef5;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .navbar {
    background-color: #21a6de;
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    h1:hover {
      cursor: pointer;
    }

    .linkButton {
      background-color: #eaeaea;
      width: 70px;
      height: 20px;
      font-size: 14px;
      margin-left: 5px;
      border: none;
      border-radius: 5px;
      box-shadow: 0px 3px 1px #7e7e7e;
    }
  
    .linkButton:nth-child(3) {
      margin-right: 10px;
    }

    .linkButton:active {
      transform: translate(0, 2px);
      box-shadow: 0px 3px 1px #7e7e7e;
    }
  }
}

@media (max-width: 420px) {
  #homeBtn {
    display: none;
  }
}